<template>
    <div class="card card-primary card-outline">
        <div class="card-header">
            <div class="card-title">
                Fahrzeug bearbeiten
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-5">
                    <div class="form-group row">
                    <label class="col-md-3 col-form-label">Name</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control form-control-sm" v-model="car.name"/>
                    </div>   
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="form-group row">
                    <label class="col-md-3 col-form-label">Kennzeichen</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control form-control-sm" v-model="car.plate"/>
                    </div>  
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="card-footer text-right">
                <button class="btn btn-primary" @click="updateCar" :disabled="car.name == '' || car.plate == '' ">
                    <i class="fas fa-save"> Speichern</i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CarDetails",
    title: 'Fahrzeug bearbeiten',


    data(){
        return {
            car: []
        };
    },

    methods: {
        loadCar() {
            this.axios
                .get("/cars/" + this.$route.params.id)
                .then((response) => {
                    this.car = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen.'
                    });
                });
        },

        updateCar()
        {
            this.axios
                .put("/cars/" + this.$route.params.id, {
                    name: this.car.name,
                    plate: this.car.plate
                })
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Fahrzeug bearbeitet",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$router.push({name: 'cars.index'});
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl was schief gelaufen.'
                    })
                });
        }
    },


    created() {
        this.loadCar();
    }
}
</script>

<style>

</style>